// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-fz-for-business-tsx": () => import("./../../../src/pages/fz-for-business.tsx" /* webpackChunkName: "component---src-pages-fz-for-business-tsx" */),
  "component---src-pages-fz-for-coaches-tsx": () => import("./../../../src/pages/fz-for-coaches.tsx" /* webpackChunkName: "component---src-pages-fz-for-coaches-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-templates-blog-page-template-tsx": () => import("./../../../src/templates/blog-page-template.tsx" /* webpackChunkName: "component---src-templates-blog-page-template-tsx" */),
  "component---src-templates-blog-post-template-tsx": () => import("./../../../src/templates/blog-post-template.tsx" /* webpackChunkName: "component---src-templates-blog-post-template-tsx" */)
}

