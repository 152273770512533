import React from "react";
import { ThemeProvider, CssBaseline } from "@material-ui/core";

import theme from "./src/style/theme";

export const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {element}
    </ThemeProvider>
  );
};
