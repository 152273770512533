import { createMuiTheme, responsiveFontSizes } from "@material-ui/core";

const cabin = {
  fontFamily: "Cabin",
  fontStyle: "normal",
  fontWeight: 400,
  src: `local('Cabin')`,
};

export const sidebarWidth = 102;
export const mobileSidebarWidth = 52;
export const appbarHeight = 55;

let theme = createMuiTheme({
  palette: {
    primary: {
      main: "#FB7415",
    },
    secondary: {
      main: "#3DC9CD",
    },
    error: {
      main: "#C23D4B",
    },
  },
  typography: {
    fontFamily: "'Cabin', sans-serif",
    body1: {
      color: "#4A4A4A",
    },
    body2: {
      color: "#4A4A4A",
    },
    h1: {
      color: "#4A4A4A",
    },
    h2: {
      color: "#4A4A4A",
    },
    h3: {
      color: "#4A4A4A",
    },
    h4: {
      color: "#4A4A4A",
    },
    h5: {
      color: "#4A4A4A",
    },
    h6: {
      color: "#4A4A4A",
    },
    subtitle1: {
      color: "#4A4A4A",
    },
    subtitle2: {
      color: "#FFFFFF",
    },
    caption: {
      color: "#4A4A4A",
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          backgroundColor: "#FFFFFF",
          display: "flex",
          minHeight: "100%",
          flexDirection: "column",
        },
        html: {
          height: "100%",
        },
        "@font-face": cabin,

        "#root": {
          display: "flex",
          flex: "1 0 auto",
        },
      },
    },
  },
  props: {
    MuiTypography: {
      variantMapping: {
        subtitle1: "p",
        subtitle2: "p",
      },
    },
  },
  mixins: {
    toolbar: {
      height: appbarHeight,
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
